'use strict';

angular.module('cpformplastApp')
  .controller('SidebarController', function ($rootScope, $scope, Auth, $state, $window, DataManager) {

    $rootScope.sideMenuShowing = true;
    $scope.menu = [];
    $scope.getCurrentUser = Auth.getCurrentUser;
    $rootScope.isLoggedIn = Auth.isLoggedIn;

    $scope.init = function() {
      $scope.menu = navMenuArray();
      $rootScope.sideMenuShowing  = false;
    };

    $scope.isItemVisible = function(item) {
      var userRole = Auth.getCurrentUser().role;
      for (var i=0 ; i<item.authenticate.length ; i++) {
        if (item.authenticate[i] === userRole) {
          return true;
        }
      }
      return false;
    };

    $scope.isItemActive = function(item) {
      const hierarchy = getStateHierarchy($state.current.name)
      return hierarchy.includes(item);
    };

    $scope.didClickItem = function(item) {
      if (item.state !== '') {
        $state.go(item.state);
        $rootScope.sideMenuShowing  = false;
        for (var i=0 ; i<this.menu.length ; i++ ) {
          this.menu[i].isOpen = false;
        }
      } else if (item.isOpen) {
        item.isOpen = false;
      } else if (item.children.length > 0) {
        for (var i=0 ; i<this.menu.length ; i++ ) {
          this.menu[i].isOpen = false;
        }
        item.isOpen = true;
        $rootScope.sideMenuShowing  = true;
      }
    };

    $rootScope.toggleSidebar = function() {
      if (!Auth.isLoggedIn()) {
        $rootScope.sideMenuShowing = false;
        return;
      }
      $rootScope.sideMenuShowing = !$rootScope.sideMenuShowing;
      if (!$rootScope.sideMenuShowing) {
        for (var i=0 ; i<this.menu.length ; i++ ) {
          this.menu[i].isOpen = false;
        }
      }
    };

    $scope.init();
});
